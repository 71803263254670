<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <details-card
          v-for="(item, index) in detailFieldsTechData"
          :key="index"
          :header="item.header"
          :edit="item.edit || false"
          :fields="item.fields"
          :beForm="beForm"
          :data="details"
          :repository="item.repository"
          @update="onUpdate"
        >
          <!-- :labels="labels" -->
        </details-card>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import BaseIcon from "@/components/BaseIcon";
import DetailsCard from "@/components/DetailsCard";

export default {
  mixins: [FormMixin, ShowMixin],
  name: "TechnicalData",
  props: {
    resourceId: Number,
  },
  components: {
    BaseIcon,
    DetailsCard,
  },
  data() {
    return {
      isLoading: false,
      repository: "insurance_policy",
      allRepositories: [],
      details: null,
      defaultLabels: {},
      mandatoryFields: {
        insurance_policy: {},
      },
      detailFields1: {
        veicolo: {
          header: "Dati Veicolo",
          edit: true,
          repository: "insurance_policy",
          fields: [
            {
              label: this.getDictionary(
                "attribute_BRAN_value",
                "insurance_policy"
              ),
              value: "attributables.BRAN",
            },
            {
              label: this.getDictionary(
                "attribute_CANIA_value",
                "insurance_policy"
              ),
              value: "attributables.CANIA",
            },
            {
              label: this.getDictionary(
                "attribute_KWHP_value",
                "insurance_policy"
              ),
              value: "attributables.KWHP",
            },
            {
              label: this.getDictionary(
                "attribute_LESC_value",
                "insurance_policy"
              ),
              value: "attributables.LESC",
            },
            {
              label: this.getDictionary(
                "attribute_MODL_value",
                "insurance_policy"
              ),
              value: "attributables.MODL",
            },
            {
              label: this.getDictionary(
                "attribute_NCLBC_value",
                "insurance_policy"
              ),
              value: "attributables.NCLBC",
            },
            {
              label: this.getDictionary(
                "attribute_NCLB_value",
                "insurance_policy"
              ),
              value: "attributables.NCLB",
            },
            {
              label: this.getDictionary(
                "attribute_OVALU_value",
                "insurance_policy"
              ),
              value: "attributables.OVALU",
            },
            {
              label: this.getDictionary(
                "attribute_POW_value",
                "insurance_policy"
              ),
              value: "attributables.POW",
            },
            {
              label: this.getDictionary(
                "attribute_QMTR_value",
                "insurance_policy"
              ),
              value: "attributables.QMTR",
            },
            {
              label: this.getDictionary(
                "attribute_QTTR_value",
                "insurance_policy"
              ),
              value: "attributables.QTTR",
            },
            {
              label: this.getDictionary(
                "attribute_REGM_value",
                "insurance_policy"
              ),
              value: "attributables.REGM",
            },
            {
              label: this.getDictionary(
                "attribute_REGY_value",
                "insurance_policy"
              ),
              value: "attributables.REGY",
            },
            {
              label: this.getDictionary(
                "attribute_REPL_value",
                "insurance_policy"
              ),
              value: "attributables.REPL",
            },
            {
              label: this.getDictionary(
                "attribute_SANIA_value",
                "insurance_policy"
              ),
              value: "attributables.SANIA",
            },
            {
              label: this.getDictionary(
                "attribute_SETP_value",
                "insurance_policy"
              ),
              value: "attributables.SETP",
            },
            {
              label: this.getDictionary(
                "attribute_STNO_value",
                "insurance_policy"
              ),
              value: "attributables.STNO",
            },
            {
              label: this.getDictionary(
                "attribute_STTL_value",
                "insurance_policy"
              ),
              value: "attributables.STTL",
            },
            {
              label: this.getDictionary(
                "attribute_STTR_value",
                "insurance_policy"
              ),
              value: "attributables.STTR",
            },
            {
              label: this.getDictionary(
                "attribute_TXHP_value",
                "insurance_policy"
              ),
              value: "attributables.TXHP",
            },
            {
              label: this.getDictionary(
                "attribute_UANIA_value",
                "insurance_policy"
              ),
              value: "attributables.UANIA",
            },
            {
              label: this.getDictionary(
                "attribute_VALU_value",
                "insurance_policy"
              ),
              value: "attributables.VALU",
            },
            {
              label: this.getDictionary(
                "attribute_VEHT_value",
                "insurance_policy"
              ),
              value: "attributables.VEHT",
            },
            {
              label: this.getDictionary(
                "attribute_WEIG_value",
                "insurance_policy"
              ),
              value: "attributables.WEIG",
            },
          ],
        },
      },
    };
  },
  methods: {
    fetchForm() {
      this.isLoading = true;
      // let queryString = `relations/byAddressBook/byCorrespondence`;
      this.fetchShowForm(this.repository, this.resourceId)
        .then((response) => {
          const data = response.data.data;
          this.details = data;
          this.fetchEditForm(this.repository, this.resourceId)
            .then(() => {
              this.initDetailCardFormData();
              this.isLoading = false;
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onUpdate(form, repository) {
      this.isLoading = true;
      const Repo = RepositoryFactory.get(repository);

      let payload = {};
      payload = { ...this.mandatoryFields[repository] };

      // for (const key in payload) {
      //   if (
      //     payload[key] === null ||
      //     payload[key] === undefined ||
      //     payload[key] === ""
      //   ) {
      //     if (![null, undefined].includes(this.beForm[repository][key].value)) {
      //       payload[key] = this.beForm[repository][key].value;
      //     }
      //     if (this.beForm[repository][key] && ![null, undefined].includes(this.beForm[repository][key].value)) {
      //       payload[key] = this.beForm[repository][key].value;
      //     }
      //   }
      //   // if (this.beForm[repository][key].type === "select") {
      //   //   payload[key] = this.beForm[repository][key].value;
      //   // }
      // }

      this.setMandatoryValues(repository, payload);

      payload = { ...payload, ...form };
      // let resId =
      //   repository === this.repository
      //     ? this.resourceId
      //     : this.repositoryResourceId[repository].id;
      // if (repository === "address_book") {
      //   resId = addressBookId;
      // }
      // console.log(resId);
      // console.log(payload);
      Repo.update(this.resourceId, payload).then(() => {
        this.$emit("fetch");
        this.fetchForm();
      });
    },
  },
  computed: {
    detailFieldsTechData() {
      return this.detailFields1;
    },
  },
  mounted() {
    this.fetchForm();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
